import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const JournalPage = () => (
  <Layout>
    <SEO title="journal" />
    <h1>From Jefferson Journal—Summer Edition 2014
</h1>
    <h2>Ron Ditch, Henderson Harbor Charter Guide Celebrates 60 Years as a Fishing
Guide</h2>
    <p>Now go build something great.</p>
    <p>Ron Ditch, Henderson Harbor started as a Lake Ontario fishing guide when he was
12 years old.  His dad, Ruddy Ditch, a licensed charter captain, had a party one day
and was too sick to take them out on the lake.  The party knew Ron as he had
accompanied his father on many trips, and they asked if he could take them out.
That might have been when he knew he would follow his father’s footsteps.  Later on
an older Ron Ditch would officially get his charter license in 1954. This year he
celebrates his 60th year as a licensed charter guide.</p>
<p>Ron was born and lived in Rochester and moved to Henderson Harbor in 1950 at
age 12.  His father Ruddy had been guiding since 1934.  Ron says he got $29.50 a
day back then, and his father’s first boat was a St. Lawrence guide boat (popularly
called “skiffs”, one of which may be viewed at the Henderson Historical Society’s
Stevens Boat Annex in Henderson).  Ron says his father Ruddy was a contractor in
Rochester and came to the eastern end of Lake Ontario to fish and guide. On one of
his trips he discovered a marina for sale in Henderson Harbor.  Being a little short on
money because of the great depression.  He told his good friend Charlie Henchen of
it and told him it would be an excellent buy. Charlie owned a bowling alley in
Rochester and was also a plumber. He had the money, and Ruddy advised him to
buy it.  That today is Henchen Marina.  Ruddy ended up with land at the end of the
harbor and built their first Ruddy’s Fishing Camp in 1950, which started as rental
cabins which he built for $50 each.</p>
<p>Ron is the proprietor today of Ruddy’s Fishing Camp, Ron Ditch & Sons.  The camp
is his home which overlooks Henderson Bay, and Ron has his living room adorned
with lots of wildlife art. Because he couldn’t fish in the winter, and hunting was his
other passion, he started carving duck hunting decoys.  That eventually led to the
creation of Ontario Decoys Inc. Ron and his partner, Paul Read sold literally
thousands of decoys and decorative carvings to such people as Abercrombie &
Fitch, The Orvis Company, Eddie Bauer and scores of other sporting goods stores
around the country. A full sized cormorant carving can be seen at the same
Historical Society Museum in Henderson.</p>
<p>He graduated from Henderson Central School where he met his wife of 59 years,
Ora Ross, in 9th grade.  Ron built today’s camp which includes lodging, a bait shop
and a marina, and guide service.  Ron and Ora have four sons, Andrew, David,
Thomas and Mark, with all but Mark, also charter guides.  </p>
<p>Ron is one of the most senior charter guides on the entire lake, and commands
respect for his knowledge and experience in the fishing world.  Henderson Harbor
Charter Guide Dave McCrea says, “I remember him and his father when I was a
little kid.  My grandfather Lyle and Ruddy chartered the same time.  Ron’s family
represents 3rd generation guides like we are.” “I’ve know him all my life, we’ve
traded a lot of secrets and lies together”, says Dave.  “He has a very broad
knowledge of the fishery and chartering.   He’s expanded that with the new species
introduced, and has seen the highs and lows”, Dave says.  “It’s exceptional that at
his age, he’s still doing it.  It’s quite an accomplishment”, Dave adds.
Listening to Ron talk about the guiding business over his 60 plus years on the lake, it
is very evident that there are two distinct phases or eras of guiding and fishing in
general, on the Great Lakes.  They are pre and post trout and salmon stocking.
Salmonoids he reminds people, disappeared in 1940, and there were no salmon or
other trout species that could be fished with prominence.  That left the 50’ and 60’s
for guides to take a party out, with smallmouth black bass, pike and perch.
“Henderson Harbor has quite a reputation for black bass”, Ron says.  Ron said his
first boat back in the 50’s when he started guiding was a Dodge Utility day cruiser
that had belonged to his Dad.  He’s had many since with his current one a 27 ft
Sportcraft. With sons David and Andrew still in the guide business with Ron, that
allows them to take a 3 boat group charter out.  </p>
<p>Back in the bass and pike days, Ron says there weren’t that many guides out of
Henderson Harbor.  Some he recalls, besides himself and his father were; Bob
Hubbard, Jim Geiger, Dutch Howing, Bob McCrea, Lyle McCrea, Gib Gertner,
Royce Irwin, Jim Ramsdell, Howard Sheppard, Art & Earl Frasier.  Only about half
were full time, Ron says. “Bob McCrea was a fireman in Watertown who would
work all night and fish all day”, Ron recalls.  Ron says that the eastern end of Lake
Ontario was tremendous bass fishing due to the shallow shores and numerous
shoals that make a great spawning area.  “This end of the lake is number one for
black bass”, Ron says.  He also notes that today there are a lot more guides but he
expects that only 7 or 8 are good bass fishermen.  “You have to know the bottom of
the lake”, Ron explains.  “Years ago you didn’t even have a depth finder.  Today you
have everything to aid fishing” says Ron.  “I think I had the first Echo White depth
finder on the lake”, he says.  “I’m a hands on type of fisherman.  I want my charters
to feel the bite and land the fish.  You don’t get that with trolling”, he adds.  “Live
bait, casting and jigging – the best methods for smallmouth bass”, Ron explains.
Shore dinners were a favorite back then, more so than today, according to Ron.  He
said he leased the North Pond on Galloo Island where shore dinners were
performed.  “I used to take  Ora and the kids, and they would stay in the ice house
that we remodeled into a camp, sometimes for 3 to 5 days at a time”, he
remembers.  </p>
<p>Today’s fishing brings many species to the angler that weren’t available in the 50’s
and 60’s.  Lake trout, Chinook, coho, and atlantic salmon, brown trout, steelhead,
and walleye have made Lake Ontario a hotbed for fishermen from all over.  Ron said
he had to adapt to the new techniques for these fish, and studied up on them.  Now
he says you don’t have to know anything about bass fishing, just how to troll.  “In
1969 I met Bill Pearce, “the father of Great Lakes fishing.  I helped drop the first
bucket of Chinook salmon in the Redfield Reservoir for experimental stocking.</p>
<p>Three
years later at the weir 20 adult salmon were sampled out of the 25,000 stocked.
They averaged 14 lamprey scars per fish, so they knew they had to control
lampreys”, Ron recalls.  “I caught my first lake trout in 1972, and John Dunk got one
that same day”, Ron remembers.  He said he experimented with everything and
taught himself how to fish for them.  “It was all brand new.  I did take a trip to Lake
Michigan with Alan Erickson, and hired a guide.  They were 14 years ahead of us”,
Ron said.  “By the way, that’s good advice for anyone, investing a lot in equipment.
Hire a guide to show you how to properly use it”, he explains.
“They used to stock two strains of lake trout, the Seneca strain from Seneca Lake,
and the Lake Clearwater strain from Canada.  The Clearwater strain was preferred
by fishermen because they provided a close to shore fishery.  Then they became
unavailable, so now you have to fish in 80 to 160 ft of water, and need specialized
equipment”,  Ron says.  He claims that the brown trout stockings which are “put and
take”, are a big boon to fishermen, as they are available in close in spring and
summer.  </p>
<p>Ron says that today’s charter guide business is a lot more competitive with the
implementation of trout and salmon.  “The pie is only so large”, he says noting that
he used to fish 140 to 150 days a year, and now mainly due to competition, he only
fishes about 50.   He points out that there is more business to be had with the
excellent fishery, but there are 4 or 5 times more boats with the charter guides and
friends taking out parties.  “I wish we could go back to just bass and northern”, Ron
emphatically states.</p>
<p>It is evident listening to Ron, that he romances about the old days and strongly
prefers bass fishing to trolling.  It is more evident that Henderson Harbor and Lake
Ontario are firmly   in planted in his blood.  Aside from his family, it is hard to think
that he would have ever wanted to do anything else for these last 60 years.</p>
  </Layout>
)

export default JournalPage
